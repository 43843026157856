$active: white;

@import url(//db.onlinewebfonts.com/c/c875cb65af48db4e990ed39d981b9ef1?family=TIM+Sans);
// @import url(//db.onlinewebfonts.com/c/57b88f8a459956fcac90a4e1a8e0f97b?family=TIM+Sans);


#root {
  width: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, "TIM Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }