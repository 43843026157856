@import url(//db.onlinewebfonts.com/c/c875cb65af48db4e990ed39d981b9ef1?family=TIM+Sans);
#root {
  width: 100vh; }

body {
  margin: 0;
  font-family: -apple-system, "TIM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  max-width: 100vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0; }

.App {
  width: 100vw;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: center;
      justify-content: center;
  justify-items: center;
  position: absolute;
  top: 0;
  left: 0; }

.top_bar {
  margin: auto;
  background-color: #004990;
  height: 10%;
  width: 100vw;
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  justify-items: center; }
  .top_bar #tim {
    height: 100%;
    width: 13%;
    margin: 10px auto 10px 15px; }
  .top_bar #distribuzione {
    height: 50%;
    width: 13%;
    margin: 10px 30px 10px auto; }

.hero-img {
  margin: auto;
  background: url(/static/media/timTutto4-1-e1535544836791.9a0ed868.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap; }
  .hero-img h1 {
    width: 90%;
    text-align: center;
    color: white;
    margin: auto;
    margin-bottom: 5%;
    -webkit-filter: drop-shadow(2px 2px 1px black);
            filter: drop-shadow(2px 2px 1px black);
    font-size: 1.8em; }

.lead_form {
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  padding: 10px 0;
  width: 90%;
  height: auto;
  border: 0.1em solid blue;
  -ms-flex-pack: center;
      justify-content: center;
  justify-items: center;
  border-radius: 15px;
  margin-top: 2em; }
  .lead_form .form_label {
    margin: auto;
    margin-bottom: 5px; }
  .lead_form .form_input {
    margin: auto;
    padding-top: 5px;
    width: 90%;
    background-color: #d1d8e6;
    border-width: 0;
    border-style: none;
    padding: 11px 0;
    border-radius: 7px;
    height: auto; }
  .lead_form .form_button {
    width: 25%;
    height: auto;
    min-width: 250px;
    margin: auto;
    margin-top: 15px;
    padding: 10px 0;
    border-radius: 10px;
    border-style: none;
    background-color: #d1d8e6; }

@media screen and (max-width: 321px) {
  .top_bar {
    padding: 10px 0; }
    .top_bar #tim {
      height: 100%;
      width: 35%;
      margin-left: 10px; }
    .top_bar #distribuzione {
      width: 35%; }
  .hero-img {
    height: 40vh;
    width: 100%; } }

@media screen and (max-width: 769px) {
  .top_bar {
    padding: 10px 0; }
    .top_bar #tim {
      height: 100%;
      width: 38%;
      margin-left: 10px; }
    .top_bar #distribuzione {
      width: 38%; }
  .hero-img {
    height: 30vh; } }

/*----------- VARIBILI COLORE ----------------*/
p {
  pointer-events: none; }

input[type="submit"] {
  font-family: "TIM Sans", sans-serif;
  font-size: 1em; }

input {
  text-align: center; }

select {
  font-family: "TIM Sans", sans-serif;
  height: 40px;
  border-style: none;
  background-color: #d4e3ee;
  -moz-text-align-last: center;
       text-align-last: center; }
  select option {
    text-align: center; }

/*--------------------- MODALE -------------------*/
.bg-modal {
  z-index: 4;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  position: fixed; }

.modal::-webkit-scrollbar {
  width: 1.2em;
  overflow: hidden; }

.modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  overflow: hidden; }

.modal::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  overflow: hidden; }

body::-webkit-scrollbar {
  width: 1.2em;
  overflow: hidden; }

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  overflow: hidden; }

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  overflow: hidden; }

.modal {
  position: fixed;
  z-index: 5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  width: 60%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 80vh;
  background-color: white;
  justify-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-radius: 30px;
  padding-bottom: 30px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
  overflow-y: auto;
  overflow-x: hidden;
  /*---------------- FORM MODALE ------------------*/ }
  .modal .thankyou {
    margin: auto;
    padding: 0 40px;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s; }
    .modal .thankyou h1 {
      text-align: center;
      font-size: 1.5em; }
  .modal form {
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    width: 90%;
    height: auto;
    -ms-flex-align: center;
        align-items: center;
    height: auto; }
    .modal form .form-wrap {
      margin: auto;
      margin-bottom: 10px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
      width: 80%;
      height: auto; }
    .modal form .labels {
      margin: auto;
      margin-bottom: 10px; }
    .modal form .inputs {
      margin: auto;
      width: 80%;
      height: 40px;
      border-radius: 5px;
      border-style: none;
      background-color: #d4e3ee; }
    .modal form #submitlead {
      width: auto;
      margin: auto;
      min-height: 50px;
      border-radius: 10px;
      border-style: none;
      background-color: green;
      color: white; }
    .modal form #submitlead:hover {
      cursor: pointer; }
    .modal form #offers {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
      margin: 15px auto;
      height: auto;
      width: 80%; }
      .modal form #offers .selects {
        margin: 7px auto;
        width: inherit; }
  .modal button {
    margin: auto;
    outline: none;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 0;
    float: right;
    width: 50px;
    min-height: 50px;
    border-radius: 10px;
    border-style: none;
    background-color: #004990;
    color: white;
    font-family: "TIM Sans", sans-serif; }
  .modal button:hover {
    cursor: pointer; }
  .modal #link {
    width: 70%; }
    .modal #link p {
      text-align: center; }
  .modal a {
    margin: auto; }

/*------------ BOTTONI APERTURA MODALE E COMPLETAMENTO ------------*/
#buttons {
  grid-area: buttons;
  -webkit-grid-area: buttons;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  height: 100%;
  width: 100%; }
  #buttons #okButton {
    grid-area: okButton;
    -webkit-grid-area: okButton;
    background-color: green;
    margin: auto;
    height: 100%;
    width: 40%;
    border-radius: 10px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-display: grid;
    -webkit-grid-auto-columns: 1fr;
    -webkit-grid-auto-rows: 1fr; }
    #buttons #okButton p {
      color: white;
      -ms-flex-item-align: center;
          align-self: center;
      justify-self: center;
      font-weight: bold; }
  #buttons #okButton:hover {
    cursor: pointer; }

#moreButton:hover {
  cursor: pointer; }

/*------------ SEZIONE TABELLA DI SELEZIONE E VISIONE OFFERTA ------------*/
.offer_table {
  margin: auto;
  width: 90%;
  height: 100%;
  margin-top: 2em;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: minmax(1fr, 480px) 1fr;
  grid-template-areas: " .  fisso  mobile  .  ." " main  main  main  main  aside";
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(5, 1fr);
  -webkit-grid-template-rows: minmax(1fr, 480px) 1fr;
  -webkit-grid-template-areas: " .  fisso  mobile  .  ." " main  main  main  main  aside"; }
  .offer_table .main {
    z-index: 1;
    border: 1px solid #708497;
    grid-area: main;
    -webkit-grid-area: main;
    display: grid;
    -webkit-display: grid;
    width: 100%;
    height: 800px;
    border-left-style: none;
    grid-template-columns: 0.2fr 1fr 2fr 0.2fr 2fr 0.2fr;
    -webkit-grid-template-columns: 0.2fr 1fr 2fr 0.2fr 2fr 0.2fr;
    grid-template-rows: 0 repeat(6, 1fr) 0;
    -webkit-grid-template-rows: 0 repeat(6, 1fr) 0;
    grid-row-gap: 5%;
    -webkit-grid-row-gap: 5%;
    grid-template-areas: " . . . . . . " " .  fisso2  fissoinfo  . costo . " " . internet  internetinfo  .  costo  ." " . voce  voceinfo  .  costo  ." " . router  routerinfo  .  initcost  ." " . . . .  initcost  ." " . buttons  buttons  .  initcost  ." " . . . . . . ";
    -webkit-grid-template-areas: " . . . . . . " " .  fisso2  fissoinfo  . costo . " " . internet  internetinfo  .  costo  ." " . voce  voceinfo  .  costo  ." " . router  routerinfo  .  initcost  ." " . . . .  initcost  ." " . buttons  buttons  .  initcost  ." " . . . . . . ";
    background-color: white; }

.offer_table {
  /*----------- TAB SUPERIORI DI SELEZIONE OFFERTA FISSA O MOBILE ----------------*/
  /*----------- SEZIONE ESPOSIZIONE OFFERTA ----------------*/
  /*----------- SELEZIONE OFFERTE ----------------*/
  /*----------- CAMBIO STATO TAB DI SELEZIONE ----------------*/ }
  .offer_table p {
    text-align: center;
    font-weight: 300; }
  .offer_table .tab {
    background-color: #d4e3ee;
    width: 100%;
    height: 100%;
    border-bottom-color: white;
    overflow: hidden;
    z-index: 0;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-display: grid;
    -webkit-grid-auto-columns: 1fr;
    -webkit-grid-auto-rows: 1fr;
    border: 1px solid #708497; }
    .offer_table .tab p {
      justify-self: center;
      -ms-flex-item-align: center;
          align-self: center;
      font-weight: bold; }
  .offer_table .active {
    border-bottom-style: none; }
  .offer_table #mobile {
    grid-area: mobile;
    -webkit-grid-area: mobile; }
  .offer_table #fisso {
    grid-area: fisso;
    -webkit-grid-area: fisso; }
  .offer_table .leftui {
    background-color: #004990;
    border-radius: 10px 0 0 10px;
    display: grid;
    -webkit-display: grid;
    grid-auto-columns: 1fr;
    -webkit-grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-grid-auto-rows: 1fr;
    height: 100%; }
    .offer_table .leftui p {
      justify-self: center;
      -ms-flex-item-align: center;
          align-self: center;
      color: white;
      font-weight: bold;
      margin: auto; }
  .offer_table .rightui,
  .offer_table #costo,
  .offer_table #initcost {
    background-color: #d4e3ee;
    display: grid;
    -webkit-display: grid;
    grid-auto-columns: 1fr;
    -webkit-grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-grid-auto-rows: 1fr; }
    .offer_table .rightui p,
    .offer_table .rightui h3,
    .offer_table #costo p,
    .offer_table #costo h3,
    .offer_table #initcost p,
    .offer_table #initcost h3 {
      justify-self: center;
      -ms-flex-item-align: center;
          align-self: center;
      margin: auto;
      padding: 5px 10px; }
  .offer_table .rightui {
    border-radius: 0 10px 10px 0;
    padding: 0 20px;
    height: 100%; }
  .offer_table #mservice {
    font-size: 14px; }
  .offer_table #costo,
  .offer_table #initcost {
    border-radius: 10px; }
  .offer_table #fisso2 {
    grid-area: fisso2;
    -webkit-grid-area: fisso2; }
  .offer_table #internet {
    grid-area: internet;
    -webkit-grid-area: internet; }
  .offer_table #voce {
    grid-area: voce;
    -webkit-grid-area: voce; }
  .offer_table #router {
    grid-area: router;
    -webkit-grid-area: router; }
  .offer_table #fissoinfo {
    grid-area: fissoinfo;
    -webkit-grid-area: fissoinfo; }
  .offer_table #internetinfo {
    grid-area: internetinfo;
    -webkit-grid-area: internetinfo; }
  .offer_table .f p {
    font-size: 0.8rem; }
  .offer_table #voceinfo {
    grid-area: voceinfo;
    -webkit-grid-area: voceinfo; }
  .offer_table #routerinfo {
    grid-area: routerinfo;
    -webkit-grid-area: routerinfo; }
  .offer_table #costo {
    margin: 30px;
    margin-top: 0;
    grid-area: costo;
    -webkit-grid-area: costo;
    display: grid;
    -webkit-display: grid;
    grid-auto-columns: 1fr;
    -webkit-grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-grid-auto-rows: 1fr; }
    .offer_table #costo h3 {
      padding: 0;
      font-size: 1.5em;
      margin-top: 0; }
  .offer_table #initcost {
    grid-area: initcost;
    -webkit-grid-area: initcost;
    margin: 30px;
    margin-bottom: 0;
    display: grid;
    -webkit-display: grid;
    grid-auto-columns: 1fr;
    -webkit-grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    -webkit-grid-auto-rows: 1fr; }
  .offer_table .wrapper {
    -ms-flex-item-align: center;
        align-self: center;
    justify-self: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap; }
    .offer_table .wrapper .wrapper-items {
      margin: auto; }
    .offer_table .wrapper h1 {
      font-size: 3em;
      padding-bottom: 5px;
      color: #004990;
      text-align: center; }
    .offer_table .wrapper h3 {
      padding: 15px 0;
      color: #C30D25;
      text-align: center; }
    .offer_table .wrapper h4 {
      color: #004990;
      text-align: center; }
    .offer_table .wrapper p {
      padding-bottom: 10px;
      text-align: center; }
  .offer_table .aside {
    grid-area: aside;
    -webkit-grid-area: aside;
    width: 100%;
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    overflow: hidden; }
    .offer_table .aside .aside-item {
      background-color: #d4e3ee;
      margin: 0 auto;
      width: 100%;
      height: 80px;
      border: 1px solid #708497;
      border-left-style: none;
      display: grid;
      -webkit-display: grid;
      grid-auto-columns: 1fr;
      -webkit-grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      -webkit-grid-auto-rows: 1fr; }
      .offer_table .aside .aside-item p {
        justify-self: center;
        -ms-flex-item-align: center;
            align-self: center;
        margin: auto;
        font-weight: bold; }
  .offer_table .active {
    background-color: white !important;
    z-index: 2; }

@media screen and (max-width: 1000px) {
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -20%);
        -ms-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    z-index: 5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 0;
    padding-bottom: 30px;
    overflow: scroll; }
    .modal form .inputs {
      width: 100%; }
    .modal form #submitlead {
      width: minmax(100px, 80%); }
    .modal button {
      height: 50px; }
  .offer_table {
    width: 100%;
    margin-top: 10px;
    grid-template-rows: 1fr;
    -webkit-grid-template-rows: 1fr;
    height: 100%;
    border-right-style: none; }
    .offer_table .aside .aside-item {
      height: 60px; }
      .offer_table .aside .aside-item p {
        padding: 0 5px;
        font-size: 0.7em; }
    .offer_table .main {
      grid-area: main;
      -webkit-grid-area: main;
      border-right-style: none;
      height: 100%;
      width: 100%;
      grid-template-rows: 0 repeat(5, 0.5fr) auto 0;
      grid-row-gap: 10px;
      grid-template-columns: repeat(7, 1fr);
      grid-template-areas: " . . . . . . . " "   fisso2 fisso2  fissoinfo fissoinfo fissoinfo  fissoinfo fissoinfo " "  internet internet internetinfo internetinfo  internetinfo internetinfo internetinfo " "  voce voce voceinfo voceinfo voceinfo  voceinfo voceinfo " "  router router routerinfo routerinfo routerinfo  routerinfo routerinfo " "  costo costo costo . initcost initcost initcost  " " . buttons buttons  buttons buttons buttons  . " " . . . . . . . ";
      -webkit-grid-template-rows: 0 repeat(5, 0.5fr) auto 0;
      -webkit-grid-row-gap: 10px;
      -webkit-grid-template-columns: repeat(7, 1fr);
      -webkit-grid-template-areas: " . . . . . . . " "   fisso2 fisso2  fissoinfo fissoinfo fissoinfo  fissoinfo fissoinfo " "  internet internet internetinfo internetinfo  internetinfo internetinfo internetinfo " "  voce voce voceinfo voceinfo voceinfo  voceinfo voceinfo " "  router router routerinfo routerinfo routerinfo  routerinfo routerinfo " "  costo costo costo . initcost initcost initcost  " " . buttons buttons  buttons buttons buttons  . " " . . . . . . . "; }
      .offer_table .main .leftui {
        border-radius: 0; }
      .offer_table .main .rightui {
        height: 100%;
        border-radius: 0; }
      .offer_table .main .f p {
        font-size: 0.8em !important; }
    .offer_table p,
    .offer_table h1,
    .offer_table h3,
    .offer_table h4 {
      font-size: 0.7em;
      font-weight: 700;
      text-align: center; }
    .offer_table #costo,
    .offer_table #initcost {
      margin: 5px; }
      .offer_table #costo h1,
      .offer_table #initcost h1 {
        font-size: 1em; }
      .offer_table #costo h3,
      .offer_table #costo h4,
      .offer_table #initcost h3,
      .offer_table #initcost h4 {
        font-size: 0.9em;
        margin: auto; }
      .offer_table #costo h3,
      .offer_table #initcost h3 {
        padding: 5px 0;
        text-align: center; }
      .offer_table #costo p,
      .offer_table #initcost p {
        font-size: 0.8em; }
    .offer_table #initcost p {
      font-size: 0.8em;
      font-weight: 700; }
    .offer_table .rightui {
      padding: 0; }
      .offer_table .rightui p {
        font-size: 1em; }
    .offer_table #buttons #okButton {
      width: 100%;
      margin: 0; }
      .offer_table #buttons #okButton P {
        font-size: 1em; } }

@media screen and (max-width: 1000px) {
  .offer_table {
    margin: auto;
    width: 90%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 0;
    border-right-style: none;
    display: grid;
    -webkit-display: grid;
    grid-template-columns: repeat(6, 1fr);
    -webkit-grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    -webkit-grid-template-rows: 1fr;
    grid-template-areas: " .  fisso  fisso  mobile mobile  ." " main  main  main  main  main main" " aside  aside  aside  aside  aside aside";
    -webkit-grid-template-areas: " .  fisso  fisso  mobile mobile  ." " main  main  main  main  main main" " aside  aside  aside  aside  aside aside"; }
    .offer_table .f p {
      font-size: 0.6em !important; } }

.privacytop {
  background-color: #004990;
  width: 100vw;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap; }
  .privacytop h1 {
    margin: auto;
    color: white;
    margin-left: 2%; }

.wrapper-privacy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  width: 100vw;
  height: auto;
  justify-items: center;
  margin-bottom: 20px; }

table {
  width: 95%;
  margin: auto;
  margin: 20px auto; }

.text-wrapper {
  margin: auto;
  width: 95%; }

