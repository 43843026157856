$radius: blue;
$input-background: rgb(209, 216, 230);
$blueTim: #004990;

.App {
  width: 100vw;
  height: auto;
  display: flex;
  margin: auto;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.top_bar {
  margin: auto;
  background-color: $blueTim;
  height: 10%;
  width: 100vw;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-items: center;

  #tim {
    height: 100%;
    width: 13%;
    margin: 10px auto 10px 15px;
  }

  #distribuzione {
    height: 50%;
    width: 13%;
    margin: 10px 30px 10px auto;

  }
}

.hero-img {
  margin: auto;
  background: url("./static/timTutto4-1-e1535544836791.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  h1 {
    width: 90%;
    text-align: center;
    color: white;
    margin: auto;
    margin-bottom: 5%;
    filter: drop-shadow(2px 2px 1px black);
    font-size: 1.8em;
  }
}

.lead_form {
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 0;
  width: 90%;
  height: auto;
  border: 0.1em solid $radius;
  justify-content: center;
  justify-items: center;
  border-radius: 15px;
  margin-top: 2em;

  .form_label {
    margin: auto;
    margin-bottom: 5px;
  }

  .form_input {
    margin: auto;
    padding-top: 5px;
    width: 90%;
    background-color: $input-background;
    border-width: 0;
    border-style: none;
    padding: 11px 0;
    border-radius: 7px;
    height: auto;
  }

  .form_button {
    width: 25%;
    height: auto;
    min-width: 250px;
    margin: auto;
    margin-top: 15px;
    padding: 10px 0;
    border-radius: 10px;
    border-style: none;
    background-color: $input-background;
  }


}

@media screen and (max-width: 321px) {


  .top_bar {
    #tim {
      height: 100%;
      width: 35%;
      margin-left: 10px,
    }

    #distribuzione {
      width: 35%;
    }

    padding: 10px 0;
  }


  .hero-img {
    height: 40vh;
    width: 100%;
  }

}

@media screen and (max-width: 769px) {
  .top_bar {
    #tim {
      height: 100%;
      width: 38%;
      margin-left: 10px,
    }

    #distribuzione {
      width: 38%;
    }

    padding: 10px 0;
  }

  .hero-img {
    height: 30vh;
  }
}