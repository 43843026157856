/*----------- VARIBILI COLORE ----------------*/
$active: white;

$inactive: rgb(212, 227, 238);

$blueTim: #004990;
$border: #708497;
$redTim: #C30D25;

p {
    pointer-events: none;
}

input[type="submit"] {
    font-family: "TIM Sans", sans-serif;
    font-size: 1em;
}

input {
    text-align: center;
}

select {
    font-family: "TIM Sans", sans-serif;
    height: 40px;
    border-style: none;
    background-color: $inactive;
    text-align-last: center;

    option {
        text-align: center;
    }
}


/*--------------------- MODALE -------------------*/

.bg-modal {
    z-index: 4;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: absolute;
    top: 0;
    left: 0;
    position: fixed;

}

.modal::-webkit-scrollbar {
    width: 1.2em;
    overflow: hidden;
}

.modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.modal::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    overflow: hidden;
}

body::-webkit-scrollbar {
    width: 1.2em;
    overflow: hidden;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    overflow: hidden;
}

.modal {
    position: fixed;
    z-index: 5;
    display: flex;
    flex-flow: column nowrap;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    background-color: white;
    justify-items: center;
    justify-content: space-between;
    border-radius: 30px;
    padding-bottom: 30px;
    transition: all 2s;
    overflow-y: auto;
    overflow-x: hidden;


    /*---------------- FORM MODALE ------------------*/
    .thankyou {
        margin: auto;
        padding: 0 40px;
        transition: all 1s;


        h1 {
            text-align: center;
            font-size: 1.5em;
        }
    }

    form {
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        width: 90%;
        height: auto;
        align-items: center;
        height: auto;

        .form-wrap {
            margin: auto;
            margin-bottom: 10px;
            display: flex;
            flex-flow: column nowrap;
            width: 80%;
            height: auto;
        }

        .labels {
            margin: auto;
            margin-bottom: 10px;
        }

        .inputs {
            margin: auto;
            width: 80%;
            height: 40px;
            border-radius: 5px;
            border-style: none;
            background-color: $inactive;
        }

        #submitlead {
            width: auto;
            margin: auto;
            min-height: 50px;
            border-radius: 10px;
            border-style: none;
            background-color: green;
            color: white;
        }

        #submitlead:hover {
            cursor: pointer;
        }

        #offers {
            display: flex;
            flex-flow: column nowrap;
            margin: 15px auto;
            height: auto;
            width: 80%;

            .selects {
                margin: 7px auto;
                width: inherit;
            }
        }
    }

    button {
        margin: auto;
        outline: none;
        margin-right: 30px;
        margin-top: 30px;
        margin-bottom: 0;
        float: right;
        width: 50px;
        min-height: 50px;
        border-radius: 10px;
        border-style: none;
        background-color: $blueTim;
        color: white;
        font-family: "TIM Sans", sans-serif;
    }

    button:hover {
        cursor: pointer;
    }

    #link {
        width: 70%;

        p {
            text-align: center;
        }


    }

    a {
        margin: auto;
    }

}

/*------------ BOTTONI APERTURA MODALE E COMPLETAMENTO ------------*/

#buttons {
    grid-area: buttons;
    -webkit-grid-area: buttons;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    width: 100%;

    #okButton {
        grid-area: okButton;
        -webkit-grid-area: okButton;
        background-color: green;
        margin: auto;
        height: 100%;
        width: 40%;
        border-radius: 10px;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-display: grid;
        -webkit-grid-auto-columns: 1fr;
        -webkit-grid-auto-rows: 1fr;

        p {
            color: white;
            align-self: center;
            justify-self: center;
            font-weight: bold;
        }
    }

    #okButton:hover {
        cursor: pointer;
    }
}

#moreButton:hover {
    cursor: pointer;
}




/*------------ SEZIONE TABELLA DI SELEZIONE E VISIONE OFFERTA ------------*/


.offer_table {
    margin: auto;
    width: 90%;
    height: 100%;
    margin-top: 2em;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: minmax(1fr, 480px) 1fr;
    grid-template-areas:
        " .  fisso  mobile  .  ."
        " main  main  main  main  aside";
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(5, 1fr);
    -webkit-grid-template-rows: minmax(1fr, 480px) 1fr;
    -webkit-grid-template-areas:
        " .  fisso  mobile  .  ."
        " main  main  main  main  aside";


    .main {
        z-index: 1;
        border: 1px solid $border;
        grid-area: main;
        -webkit-grid-area: main;
        display: grid;
        -webkit-display: grid;
        width: 100%;
        height: 800px;
        border-left-style: none;
        grid-template-columns: 0.2fr 1fr 2fr 0.2fr 2fr 0.2fr;
        -webkit-grid-template-columns: 0.2fr 1fr 2fr 0.2fr 2fr 0.2fr;
        grid-template-rows: 0 repeat(6, 1fr) 0;
        -webkit-grid-template-rows: 0 repeat(6, 1fr) 0;
        grid-row-gap: 5%;
        -webkit-grid-row-gap: 5%;
        grid-template-areas:
            " . . . . . . "
            " .  fisso2  fissoinfo  . costo . "
            " . internet  internetinfo  .  costo  ."
            " . voce  voceinfo  .  costo  ."
            " . router  routerinfo  .  initcost  ."
            " . . . .  initcost  ."
            " . buttons  buttons  .  initcost  ."
            " . . . . . . ";
        -webkit-grid-template-areas:
            " . . . . . . "
            " .  fisso2  fissoinfo  . costo . "
            " . internet  internetinfo  .  costo  ."
            " . voce  voceinfo  .  costo  ."
            " . router  routerinfo  .  initcost  ."
            " . . . .  initcost  ."
            " . buttons  buttons  .  initcost  ."
            " . . . . . . ";
        background-color: $active;
    }
}



.offer_table {



    p {
        text-align: center;
        font-weight: 300;
    }



    /*----------- TAB SUPERIORI DI SELEZIONE OFFERTA FISSA O MOBILE ----------------*/

    .tab {
        background-color: $inactive;
        width: 100%;
        height: 100%;
        border-bottom-color: white;
        overflow: hidden;
        z-index: 0;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-display: grid;
        -webkit-grid-auto-columns: 1fr;
        -webkit-grid-auto-rows: 1fr;
        border: 1px solid $border;


        p {
            justify-self: center;
            align-self: center;
            font-weight: bold;
        }


    }

    .active {
        border-bottom-style: none;
    }

    #mobile {
        grid-area: mobile;
        -webkit-grid-area: mobile;
    }

    #fisso {
        grid-area: fisso;
        -webkit-grid-area: fisso;
    }

    /*----------- SEZIONE ESPOSIZIONE OFFERTA ----------------*/



    .leftui {
        background-color: $blueTim;
        border-radius: 10px 0 0 10px;
        display: grid;
        -webkit-display: grid;
        grid-auto-columns: 1fr;
        -webkit-grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-grid-auto-rows: 1fr;
        height: 100%;

        p {
            justify-self: center;
            align-self: center;
            color: white;
            font-weight: bold;
            margin: auto;
        }
    }

    .rightui,
    #costo,
    #initcost {
        background-color: $inactive;
        display: grid;
        -webkit-display: grid;
        grid-auto-columns: 1fr;
        -webkit-grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-grid-auto-rows: 1fr;

        p,
        h3 {
            justify-self: center;
            align-self: center;
            margin: auto;
            padding: 5px 10px;
        }
    }

    .rightui {
        border-radius: 0 10px 10px 0;
        padding: 0 20px;
        height: 100%;

    }

    #mservice {

        font-size: 14px;

    }

    #costo,
    #initcost {
        border-radius: 10px;
    }

    #fisso2 {
        grid-area: fisso2;
        -webkit-grid-area: fisso2;
    }

    #internet {
        grid-area: internet;
        -webkit-grid-area: internet;
    }

    #voce {
        grid-area: voce;
        -webkit-grid-area: voce;
    }

    #router {
        grid-area: router;
        -webkit-grid-area: router;
    }

    #fissoinfo {
        grid-area: fissoinfo;
        -webkit-grid-area: fissoinfo;
    }

    #internetinfo {
        grid-area: internetinfo;
        -webkit-grid-area: internetinfo;
    }

    .f {
        p {
            font-size: 0.8rem;
        }
    }

    #voceinfo {
        grid-area: voceinfo;
        -webkit-grid-area: voceinfo;
    }

    #routerinfo {
        grid-area: routerinfo;
        -webkit-grid-area: routerinfo;
    }

    #costo {
        margin: 30px;
        margin-top: 0;
        grid-area: costo;
        -webkit-grid-area: costo;
        display: grid;
        -webkit-display: grid;
        grid-auto-columns: 1fr;
        -webkit-grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-grid-auto-rows: 1fr;

        h3 {
            padding: 0;
            font-size: 1.5em;
            margin-top: 0,
        }
    }

    #initcost {
        grid-area: initcost;
        -webkit-grid-area: initcost;
        margin: 30px;
        margin-bottom: 0;
        display: grid;
        -webkit-display: grid;
        grid-auto-columns: 1fr;
        -webkit-grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        -webkit-grid-auto-rows: 1fr;
    }

    .wrapper {
        align-self: center;
        justify-self: center;
        display: flex;
        flex-flow: column nowrap;

        .wrapper-items {
            margin: auto;
        }

        h1 {
            font-size: 3em;
            padding-bottom: 5px;
            color: $blueTim;
            text-align: center;

        }

        h3 {
            padding: 15px 0;
            color: $redTim;
            text-align: center;

        }

        h4 {
            color: $blueTim;
            text-align: center;
        }

        p {
            padding-bottom: 10px;
            text-align: center;
        }
    }


    /*----------- SELEZIONE OFFERTE ----------------*/


    .aside {
        grid-area: aside;
        -webkit-grid-area: aside;
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;





        .aside-item {
            background-color: $inactive;
            margin: 0 auto;
            width: 100%;
            height: 80px;
            border: 1px solid $border;
            border-left-style: none;
            display: grid;
            -webkit-display: grid;
            grid-auto-columns: 1fr;
            -webkit-grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            -webkit-grid-auto-rows: 1fr;

            p {
                justify-self: center;
                align-self: center;
                margin: auto;
                font-weight: bold;
            }
        }
    }

    /*----------- CAMBIO STATO TAB DI SELEZIONE ----------------*/

    .active {
        background-color: $active !important;
        z-index: 2;
    }


}



@media screen and (max-width: 1000px) {

    .modal {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -20%);
        z-index: 5;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 0;
        padding-bottom: 30px;
        overflow: scroll;


        form {

            .inputs {
                width: 100%;
            }

            #submitlead {
                width: minmax(100px, 80%);

            }
        }

        button {
            height: 50px;
        }
    }

    .offer_table {
        width: 100%;
        margin-top: 10px;
        grid-template-rows: 1fr;
        -webkit-grid-template-rows: 1fr;
        height: 100%;
        border-right-style: none;

        .aside {
            .aside-item {
                height: 60px;

                p {
                    padding: 0 5px;
                    font-size: 0.7em;
                }
            }
        }

        .main {
            grid-area: main;
            -webkit-grid-area: main;
            border-right-style: none;
            height: 100%;
            width: 100%;
            grid-template-rows: 0 repeat(5, 0.5fr) auto 0;
            grid-row-gap: 10px;
            grid-template-columns: repeat(7, 1fr);
            grid-template-areas:
                " . . . . . . . "
                "   fisso2 fisso2  fissoinfo fissoinfo fissoinfo  fissoinfo fissoinfo "
                "  internet internet internetinfo internetinfo  internetinfo internetinfo internetinfo "
                "  voce voce voceinfo voceinfo voceinfo  voceinfo voceinfo "
                "  router router routerinfo routerinfo routerinfo  routerinfo routerinfo "
                "  costo costo costo . initcost initcost initcost  "
                " . buttons buttons  buttons buttons buttons  . "
                " . . . . . . . ";
            -webkit-grid-template-rows: 0 repeat(5, 0.5fr) auto 0;
            -webkit-grid-row-gap: 10px;
            -webkit-grid-template-columns: repeat(7, 1fr);
            -webkit-grid-template-areas:
                " . . . . . . . "
                "   fisso2 fisso2  fissoinfo fissoinfo fissoinfo  fissoinfo fissoinfo "
                "  internet internet internetinfo internetinfo  internetinfo internetinfo internetinfo "
                "  voce voce voceinfo voceinfo voceinfo  voceinfo voceinfo "
                "  router router routerinfo routerinfo routerinfo  routerinfo routerinfo "
                "  costo costo costo . initcost initcost initcost  "
                " . buttons buttons  buttons buttons buttons  . "
                " . . . . . . . ";

            .leftui {
                border-radius: 0;
            }

            .rightui {
                height: 100%;
                border-radius: 0;



            }

            .f {
                p {
                    font-size: 0.8em !important;
                }
            }


        }


        p,
        h1,
        h3,
        h4 {
            font-size: 0.7em;
            font-weight: 700;
            text-align: center;
        }

        #costo,
        #initcost {
            margin: 5px;

            h1 {
                font-size: 1em;
            }

            h3,
            h4 {
                font-size: 0.9em;
                margin: auto;
            }

            h3 {
                padding: 5px 0;
                text-align: center;
            }

            p {
                font-size: 0.8em;
            }
        }



        #initcost {
            p {
                font-size: 0.8em;
                font-weight: 700;
            }
        }

        .rightui {
            padding: 0;

            p {
                font-size: 1em;
            }
        }

        #buttons {
            #okButton {
                width: 100%;
                margin: 0;

                P {
                    font-size: 1em;
                }
            }
        }
    }
}



@media screen and (max-width: 1000px) {
    .offer_table {

        margin: auto;
        width: 90%;
        height: 100%;
        margin-top: 2em;
        margin-bottom: 0;
        border-right-style: none;
        display: grid;
        -webkit-display: grid;
        grid-template-columns: repeat(6, 1fr);
        -webkit-grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        -webkit-grid-template-rows: 1fr;
        grid-template-areas:
            " .  fisso  fisso  mobile mobile  ."
            " main  main  main  main  main main"
            " aside  aside  aside  aside  aside aside";

        -webkit-grid-template-areas:
            " .  fisso  fisso  mobile mobile  ."
            " main  main  main  main  main main"
            " aside  aside  aside  aside  aside aside";

        .f {
            p {
                font-size: 0.6em !important;
            }
        }
    }
}