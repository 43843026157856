 $blueTim: #004990;

 .privacytop {
     background-color: $blueTim;
     width: 100vw;
     height: 50px;
     display: flex;
     flex-flow: column nowrap;

     h1 {
         margin: auto;
         color: white;
         margin-left: 2%;
     }
 }

 .wrapper-privacy {
     display: flex;
     flex-flow: column nowrap;
     width: 100vw;
     height: auto;
     justify-items: center;
     margin-bottom: 20px;
 }

 table {
     width: 95%;
     margin: auto;
     margin: 20px auto;
 }

 .text-wrapper {
     margin: auto;
     width: 95%;
 }